import Swiper, { Autoplay, EffectCreative, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let firstSwiperWr = document.querySelectorAll('.first-swiper-wr');
firstSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, EffectCreative],
      observer: false, // Disable observer
      observeParents: false, // Disable observeParents
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        551: {
          spaceBetween: 16,
          slidesPerView: 2,
          autoplay: false,
        },
        768: {
          allowTouchMove: false,
          autoplay: false,
        },
        1024: {
          spaceBetween: 20,
          slidesPerView: 2.8,
          autoplay: false,
          allowTouchMove: false,
        },
        1200: {
          slidesPerView: 2.3,
          autoplay: false,
          allowTouchMove: false,
        },
        1400: {
          slidesPerView: 2.2,
          autoplay: false,
          allowTouchMove: false,
        },
      },
      effect: 'creative',
      creativeEffect: {
        limitProgress: 3,
        prev: {
          translate: [0, 0, -320],
          opacity: 0,
          effect: 'scale',
          scale: 0.6,
        },
        next: {
          translate: [
            'calc(100% + clamp(8px, 2.67857vw - 8.57143px, 20px))',
            0,
            0,
          ],
          opacity: 1,
          effect: 'scale',
        },
      },

      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
    });

    // Manually update the Swiper when the DOM changes
    window.addEventListener('resize', () => {
      swiper.update();
    });
  }
});