import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

function equalizeTeamCardHeights(swiper) {
  const wrapper = swiper.el.querySelector('.swiper-wrapper');
  if (!wrapper) return;

  const cards = wrapper.querySelectorAll('.team-card');
  let maxHeight = 0;

  cards.forEach(card => {
    card.style.height = 'auto';
    const height = card.offsetHeight;
    maxHeight = Math.max(maxHeight, height);
  });

  cards.forEach(card => {
    card.style.height = `${maxHeight}px`;
  });
}

let teamsSwiperWr = document.querySelectorAll('.teams-swiper-wr');
teamsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true,
      observeParents: true,
      spaceBetween: 24,
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        640: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        1024: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 640,
      },
      on: {
        afterInit: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
          equalizeTeamCardHeights(this);
        },
        resize: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
          equalizeTeamCardHeights(this);
        },
        slideChangeTransitionEnd: function () {
          equalizeTeamCardHeights(this);
        },
      },
    });
    swiperObserver(swiper);
  }
});

window.addEventListener('load', () => {
  teamsSwiperWr.forEach((el) => {
    const swiper = el.swiper;
    if (swiper) {
      equalizeTeamCardHeights(swiper);
    }
  });
});

window.addEventListener('resize', () => {
  teamsSwiperWr.forEach((el) => {
    const swiper = el.swiper;
    if (swiper) {
      equalizeTeamCardHeights(swiper);
    }
  });
});