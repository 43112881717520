import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let testimonialsSwiperWr = document.querySelectorAll('.testimonials-swiper-wr');
testimonialsSwiperWr.forEach((el, idx) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 16,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      loop: true,
      speed: 12000,
      allowTouchMove: false,
      breakpoints: {
        1024: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
      },
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
        reverseDirection: idx % 2 ? true : false,
      },
    });

    el.addEventListener('mouseenter', () => {
      swiper.setTransition(0); // Set speed to 0 on hover
      swiper.autoplay.stop();
      swiper.autoplay.running = false;
      swiper.params.speed = 0; // Set speed to 0 on hover
      swiper.update();
    });

    el.addEventListener('mouseleave', () => {
      swiper.setTransition(12000);
      swiper.autoplay.start(); // Restore original speed
      swiper.params.speed = 12000; // Restore original speed
      swiper.update();
    });

    /*swiperObserver(swiper);*/
  }
});
