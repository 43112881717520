import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let coursesSwiperWr = document.querySelectorAll('.courses-swiper-wr');
coursesSwiperWr.forEach((el) => {
  if (el && el.querySelector('.swiper')) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 20,
      slidesPerView: 1,
      threshold: 10,
      centeredSlides: true,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      551: {
        spaceBetween: 22,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 1023,
      },
    });
    swiperObserver(swiper);
  } else {
    if (el) {
      el.querySelector('.swiper-buttons').remove();
    }
  }
});
