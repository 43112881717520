import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let communitySwiperWr = document.querySelectorAll('.community-swiper-wr');
communitySwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let itemsArr = el.querySelectorAll('.swiper-slide');
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    if (slidesCount <= 1) {
      nextEl.style.display = 'none';
      prevEl.style.display = 'none';
  }

    swiperOnResize('(max-width: 768px)', swiperEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 800,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      breakpoints: {},
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });

    let current = 0,
      interval;

    const prevSlide = (elem, index, strClass) => {
      elem[index].classList.remove(strClass);
    };

    const nextSlide = (elem, index, strClass) => {
      elem[index].classList.add(strClass);
    };

    const nextSlideActive = () => {
      prevSlide(itemsArr, current, 'is-active');
      current++;
      if (current >= itemsArr.length) {
        current = 0;
      }
      nextSlide(itemsArr, current, 'is-active');
    };

    const prevSlideActive = () => {
      prevSlide(itemsArr, current, 'is-active');
      current--;
      if (current < 0) {
        current = itemsArr.length - 1;
      }
      nextSlide(itemsArr, current, 'is-active');
    };

    const startAuto = () => {
      interval = setInterval(nextSlideActive, 3000);
    };

    const stopAuto = () => {
      clearInterval(interval);
    };

    const toggleSlideContent = (index) => {
      current = index;
      for (let i = 0; i < itemsArr.length; i++) {
        if (index === i) {
          itemsArr[i].classList.add('is-active');
        } else {
          itemsArr[i].classList.remove('is-active');
        }
      }
    };

    itemsArr.forEach((item, i) => {
      item.addEventListener('click', () => {
        toggleSlideContent(i);
      });
    });

    nextEl.addEventListener('click', () => {
      nextSlideActive();
    });

    prevEl.addEventListener('click', () => {
      prevSlideActive();
    });

    el.addEventListener('mouseenter', () => {
      stopAuto();
    });

    el.addEventListener('mouseleave', () => {
      startAuto();
    });
    startAuto();

    /*itemsArr.forEach((item) => {
      item.addEventListener('click', () => {
        if (!item.classList.contains('is-active')) {
          item.classList.add('is-active');
        } else {
          item.classList.remove('is-active');
        }
      });
    });*/
  }
});
