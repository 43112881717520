if (document.getElementsByClassName('accordion-section__accordion')[0]) {
  const accordionsArr = document.querySelectorAll(
    '.accordion-section__accordion'
  );

  accordionsArr.forEach((accordion) => {
    const itemsArr = accordion.querySelectorAll('.accordion-section__item');
    const imagesArr = accordion.parentElement.querySelectorAll(
      '.accordion-section__image'
    );
    console.log(imagesArr);

    itemsArr.forEach((item, i) => {
      const hiddenEl = item.querySelector('.accordion-section__item-hidden');
      const contentEl = item.querySelector('.accordion-section__item-content');
      let maxHeight = `${contentEl.clientHeight + 50}px`;

      item.addEventListener('click', (event) => {
        if (hiddenEl.contains(event.target)) {
          return;
        }

        if (!item.classList.contains('active')) {
          itemsArr.forEach((el) => el.classList.remove('active'));
          imagesArr.forEach((el) => el.classList.remove('active'));
          item.classList.add('active');
          imagesArr[i].classList.add('active');
          console.log(imagesArr[i]);
          maxHeight = `${contentEl.clientHeight + 50}px`;
          hiddenEl.style.maxHeight = maxHeight;
        } else {
          item.classList.remove('active');
        }
      });
    });
  });
}
