import marquee from 'vanilla-marquee/dist/vanilla-marquee.js';

if (document.querySelector('.testimonials-section__marquee')) {
  const marqueeArray = [];

  const marqueeArr = document.querySelectorAll(
    '.testimonials-section__marquee'
  );
  marqueeArr.forEach((marqueeEl, i) => {
    const container = 1440;

    const finalItemsArr = marqueeEl.querySelectorAll('.testimonial-card');

    let duration = 20000;

    const totalWidth =
      finalItemsArr.length * (finalItemsArr[0].clientWidth + 12);
    let baseSpeed = 4;

    const scalingFactor = window.innerWidth < 768 ? 1 : 2;
    baseSpeed *= scalingFactor;

    duration = (totalWidth / window.innerWidth) * baseSpeed;

    let direction = 'up';
    let pauseOnHover = true;

    if (window.innerWidth <= 1024) {
      pauseOnHover = false;
    }

    direction = 'left';
    if (i % 2) {
      direction = 'right';
    }

    const mq = new marquee(marqueeEl, {
      delayBeforeStart: 0,
      direction: direction,
      pauseOnHover: pauseOnHover,
      duplicated: true,
      recalcResize: true,
      startVisible: true,
      gap: window.innerWidth >= 768 ? 20 : 16,
      duration: duration * 1000,
    });

    marqueeArray.push(mq);

    if (window.innerWidth <= 1024) {
      setTimeout(function () {
        const itemsArr = marqueeEl.querySelectorAll('.testimonial-card');
        itemsArr.forEach((item) => {
          item.addEventListener('click', (e) => {
            if (item.classList.contains('is-hover')) {
              item.classList.remove('is-hover');
              mq.resume();
            } else {
              const newArr = document.querySelectorAll('.testimonial-card');
              for (let j = 0; j < newArr.length; j++) {
                newArr[j].classList.remove('is-hover');
              }
              item.classList.add('is-hover');
              for (let j = 0; j < marqueeArray.length; j++) {
                marqueeArray[j].resume();
              }
              mq.pause();
            }
          });
        });
      }, 100);
    }
  });

  document.addEventListener('click', function (event) {
    if (!marqueeArr[0].parentElement.contains(event.target)) {
      const newArr = document.querySelectorAll('.testimonial-card');
      for (let j = 0; j < newArr.length; j++) {
        newArr[j].classList.remove('is-hover');
      }
      for (let j = 0; j < marqueeArray.length; j++) {
        marqueeArray[j].resume();
      }
    }
  });
}

if (document.querySelector('.jobs-section__marquee')) {
  const marqueeArrayJobs = [];
  const jobsMarqueeEl = document.querySelector('.jobs-section__marquee');
  const jobsMarqueeInner = document.querySelector(
    '.jobs-section__marquee-inner'
  );
  const jobCards = jobsMarqueeInner.querySelectorAll('.job-card');

  jobCards.forEach((card) => {
    for (let i = 0; i < 6; i++) {
      const clone = card.cloneNode(true);
      jobsMarqueeInner.appendChild(clone);
    }
  });

  let newJobsCardsArr = jobsMarqueeInner.querySelectorAll('.job-card');

  if (window.innerWidth <= 1024) {
    newJobsCardsArr.forEach((item) => {
      if (item.tagName.toLowerCase() === 'a') {
        const href = item.getAttribute('href');
        item.removeAttribute('href');
        item.setAttribute('data-href', href);

        const div = document.createElement('div');
        div.className = item.className;
        div.innerHTML = item.innerHTML;

        Array.from(item.attributes).forEach((attr) => {
          if (attr.name !== 'href') {
            div.setAttribute(attr.name, attr.value);
          }
        });

        item.parentNode.replaceChild(div, item);
      }
    });

    newJobsCardsArr = jobsMarqueeInner.querySelectorAll('.job-card');
  }

  const totalWidth = Array.from(jobCards).reduce(
    (sum, card) => sum + card.offsetWidth + 20,
    0
  );
  const duration = 30;
  const pauseOnHover = window.innerWidth > 1024;

  const mqJobs = new marquee(jobsMarqueeEl, {
    delayBeforeStart: 0,
    direction: 'left',
    duplicated: true,
    pauseOnHover: pauseOnHover,
    startVisible: true,
    speed: 50,
    duration: duration * 1000,
    gap: 0,
  });

  marqueeArrayJobs.push(mqJobs);

  if (window.innerWidth <= 1024) {
    jobsMarqueeEl.addEventListener('click', (e) => {
      const target = e.target.closest('.job-card');
      if (target) {
        if (jobsMarqueeEl.classList.contains('is-paused')) {
          target.classList.remove('is-hover');
          const href = target.getAttribute('data-href');
          if (href) {
            window.location.href = href;
          }
        } else {
          if (target.classList.contains('is-hover')) {
            target.classList.remove('is-hover');
            const href = target.getAttribute('data-href');
            if (href) {
              window.location.href = href;
            }
          } else {
            document
              .querySelectorAll('.job-card')
              .forEach((card) => card.classList.remove('is-hover'));
            target.classList.add('is-hover');
            mqJobs.pause();
            marqueeArrayJobs[0].pause();
            jobsMarqueeEl.classList.add('is-paused');
          }
        }
      }
    });
  }

  document.addEventListener('click', (event) => {
    if (!jobsMarqueeEl.contains(event.target)) {
      document
        .querySelectorAll('.job-card')
        .forEach((card) => card.classList.remove('is-hover'));
      mqJobs.resume();
      jobsMarqueeEl.classList.remove('is-paused');
    }
  });
}
