const doubleRangeSlider = () => {
  const doubleRange = document.getElementsByClassName(
    'double-range-default'
  )[0];
  if (doubleRange) {
    const doubleRangesArr = document.querySelectorAll('.double-range-default');

    doubleRangesArr.forEach((doubleRangeEl) => {
      const slider = doubleRangeEl.querySelector('#double-range-default');
      const max = +slider.dataset.max;
      const min = +slider.dataset.min;
      const unit = slider.dataset?.unit || '£';
      const step = +slider.dataset.step;
      const inputsHidden = doubleRangeEl.querySelectorAll(
        '.double-range-hidden-input'
      );
      const inputInfo = doubleRangeEl.querySelector('.double-range-info');
      const startValueMin = +inputsHidden[0].value;
      const startValueMax = +inputsHidden[1].value;

      noUiSlider.create(slider, {
        start: [startValueMin, startValueMax],
        connect: true,
        margin: 10,
        step: step,
        range: {
          min: min,
          max: max,
        },
      });

      slider.noUiSlider.on('update', function (values, handle) {
        inputInfo.textContent = `Values: ${
          unit + Math.round(values[0]).toLocaleString('en-GB')
        } - ${unit + Math.round(values[1]).toLocaleString('en-GB')}`;

        inputsHidden[handle].value = Math.round(values[handle]);
      });
    });
  }
};
doubleRangeSlider();
