import Swiper, { Autoplay } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let gallerySwiperWr = document.querySelectorAll('.gallery-swiper-wr');
gallerySwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay],
      observer: true,
      observeParents: true,
      spaceBetween: 20,
      slidesPerView: 1.5,
      loop: true,
      loopedSlides: slidesCount,
      speed: 5000, // Slow speed for smooth scrolling
      allowTouchMove: false, // Disable touch/drag
      autoplay: {
        delay: 0, // Start immediately
        disableOnInteraction: false,
      },
      breakpoints: {
        551: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        }
      },
      on: {
        afterInit: function () {
          wrapper.style.display = 'flex';
          wrapper.style.transition = 'transform 0s linear';
        },
      },
    });

    swiper.on('beforeInit', function () {
      if (slidesCount > 1) {
        let slides = Array.from(swiperEl.querySelectorAll('.swiper-slide'));
        slides.forEach((slide) => {
          wrapper.appendChild(slide.cloneNode(true));
        });
      }
    });

    swiperObserver(swiper);
  }
});