import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './utils/parallax-on-view.utils.js';

import './components/header.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/animations.component.js';
import './components/accordion.component.js';
import './components/jobs.component.js';
import './components/date.component.js';
import './components/ranges.component.js';

import './components/marquee.component.js';

/*Sliders*/
// import './components/swiper-jobs.component.js';
import './components/swiper-first.component.js';
import './components/swiper-teams.component.js';
import './components/swiper-community.component.js';
import './components/swiper-testimonials.component.js';
import './components/swiper-stats.component.js';
import './components/swiper-courses.component.js';
import './components/swiper-blogs.component.js';
import './components/swiper-related-jobs.component.js';
import './components/swiper-benefits.component.js';
import './components/swiper-gallery.component.js';
import './components/swiper-values.component.js';
/*Sliders End*/

import './components/overlayScroll.component.js';

import './libs/countUp.lib.js';

window.jsScrollToEl = function jsScrollToEl(targetID) {
  let config = targetID.split('|');
  let duration = +config[1] && +config[1] !== '' ? +config[1] : 800;
  let paddingTop = +config[2] && +config[2] !== '' ? +config[2] : 0;
  let target = config[0];
  let targetElement = document.querySelector(target);

  if (targetElement) {
    let targetOffsetTop = targetElement.offsetTop - paddingTop;
    window.scrollTo({
      top: targetOffsetTop,
      behavior: 'smooth',
    });
    return false;
  }
};

/* Enforce number input pattern */
const telInputs = document.querySelectorAll('input[type="tel"]');

telInputs.forEach((input) => {
  input.addEventListener('input', function () {
    this.value = this.value.replace(/[^0-9+]/g, '');
  });
});
