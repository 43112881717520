import AirDatepicker from 'air-datepicker';

const localeEn = {
  days: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  monthsShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  today: 'Today',
  clear: 'Clear',
  dateFormat: 'MM/dd/yyyy',
  timeFormat: 'hh:mm aa',
  firstDay: 0,
};

function dateInput() {
  if (document.getElementsByClassName('date-input')[0]) {
    const dateInputsArr = document.querySelectorAll('.date-input');
    dateInputsArr.forEach((dateInput, i) => {
      if (!dateInput.classList.contains('is-init')) {
        dateInput.classList.add('is-init');
        const airDatepicker = new AirDatepicker(dateInput, {
          dateFormat: 'dd/MM/yyyy',
          locale: localeEn,
          onSelect: function (formattedDate, date, inst) {
            dateInput.value = formattedDate.formattedDate;

            if (
              dateInput.value === undefined ||
              dateInput.value === 'undefined'
            ) {
              dateInput.value = '';
            }
          },
        });

        /*document.addEventListener('scroll', () => {});

        document.addEventListener('wheel', (event) => {
          console.log('Wheel event triggered');
          const datepickerElement = document.querySelector('.air-datepicker');

          if (
            datepickerElement &&
            document.querySelector('#popup').classList.contains('active')
          ) {
            airDatepicker.hide();
          }
        });*/
      }
    });
  }
}
dateInput();
